import React from 'react'
import {Link} from 'gatsby'
import * as styles from './frontpagelink.module.css'

const FrontPageLink = () => (
  <div className={styles.link}>
    <Link to={'/'}>← Etusivulle</Link>
  </div>
)

export default FrontPageLink
