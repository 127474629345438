import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FrontPageLink from '../components/FrontPageLink'

const Contacts = () => (
  <Layout img={'3'}>
    <SEO title="Yhteystiedot" />
    <FrontPageLink />
    <h2>Yhteystiedot</h2>
    <p>Seyran Pohja</p>
    <p>
      Puhelin: <a href={'tel:+358405149851'}>040 514 9851</a>
    </p>
    <p>
      Sähköposti: <a href={'mailto:seikku@sokkihoito.fi'}>seikku@sokkihoito.fi</a>
    </p>
    <p>Y-tunnus: 2979213-6</p>
  </Layout>
)

export default Contacts
